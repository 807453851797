<template>
    <div>
     <topHead></topHead>
     <div class="recordWrap">
        <div  style="text-align: center;font-size: 24px;color: #333;height: 50px;line-height:50px;font-weight: bold;">考试记录</div>
             <el-table  v-loading="loading"
                 :data="tableData" :cell-style="rowStyle"
                 style="width: 100%">
                 
                 <el-table-column
                     prop="expertName"
                     label="用户"
                     align="center"
                     >
                     <!-- {{ userInfo.personNo }} -->
                 </el-table-column>
                 <el-table-column
                     prop="bokName"
                     label="知识体系名称"
                     align="center"
                     >
                 </el-table-column>
                 
                 <!-- <el-table-column
                     prop="subjectName"
                     label="所学科目名称"
                     align="center"
                     >
                 </el-table-column> -->
                 <!-- <el-table-column
                     prop="examPaper.name"
                     label="试卷名称"
                     >
                 </el-table-column> -->

                 <el-table-column
                     prop="beginTime"
                     label="考试开始时间"
                     align="center"
                     >
                 </el-table-column>
                 <el-table-column
                     prop="endTime"
                     label="考试结束时间"
                     align="center"
                     >
                 </el-table-column>
                 <el-table-column
                     label="是否通过考试" align="center">
                     <template slot-scope="scope">
                         <span v-if="scope.row.isPass == 0">否</span>
                         <span v-if="scope.row.isPass == 1">是</span>
                     </template>   
                 </el-table-column>
                 <el-table-column
                     prop="totalAnswerScore"
                     align="center"
                     label="考试成绩">
                 </el-table-column>
             </el-table>
 
             <el-pagination class="mt20" background style="text-align:center;margin-top:20px;" v-if="(total>15)"
                     @current-change="handleCurrentChange"
                     :page-size="params.pageSize" 
                     :current-page="params.pageNum" 
                     :total="total"></el-pagination>
 
             <!-- <el-button type="primary" @click="exprot" style="margin-top:20px;">导出记录</el-button> -->
         </div>
        

     <foot></foot>
    </div>
 </template> 
 <script>
 import netTool from '../../api/netTool';
     import foot  from '../../components/footer.vue';
     import topHead  from '../../components/topHead.vue';
 export default {
     
     data() {
         return {
             userInfo:{},
             personName:'',
             tableData:[],
             total:0,
             params:{
                 pageNum: 1,
                 pageSize: 15,
                 platformId: this.$store.state.pfId,
                 expertUuid: localStorage.getItem('expertUuid'),
                 category:localStorage.getItem('userType') == '3' ? 1: 2
             },
             loading: false
         }
     },
     components:{
         foot,
         topHead
     },
     created(){
         this.userInfo = this.$store.state.userInfo;
         this.personName = localStorage.getItem('uuid')
         this.getRecordList()
         document.title = "培训考试系统";
     },
     methods:{
        //  exprot(){
        //      this.$message({
        //          message: '导出记录成功！',
        //          type: 'success'
        //      });
        //  },
        rowStyle(){
                return "text-align:center"
            },
         // 查询考试记录列表
         
         getRecordList(){
            this.loading = true;
             netTool.examineRecord(this.params).then(res => {
                 this.tableData = res.data.rows
                 this.total = res.data.total;
                 this.loading = false;
             })
         },
         handleCurrentChange(val){
             this.params.pageNum = val;
             this.getRecordList();
         },
     }
    
 }
 </script>
 <style scoped lang="scss">
 
 .recordWrap{
     width: 1200px;
     margin:0 auto;
     padding-top: 50px;
     box-sizing: border-box;
     min-height:calc(100vh - 260px);
 }
 
 
 
 </style>
     